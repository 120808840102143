<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  class="mr-1"
                  :to="{ name: 'apps-logisticsfeed-edit'}"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">新增</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  variant="outline-danger"
                  class="mr-1"
                  @click="delByIds"
              >
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量删除</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="outline-info"
                  class="mr-1"
                  @click="clearSelected"
              >
                <feather-icon
                    icon="SlashIcon"
                    class="mr-50"
                />
                <span class="align-middle">取消勾选</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--客户名称-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="客户名称"
                    label-for="customer_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="customer_name"
                      v-model="condition.customer_name"
                      :options=customer_name_options
                      class="select-size-sm"
                      placeholder="请选择客户名称"
                      :reduce="option => option.label"
                  />
                </b-form-group>
              </b-col>
              <!--承运人-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="承运人"
                    label-for="carriager"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="carriager"
                      v-model="condition.carriager"
                      :options=carriager_options
                      class="select-size-sm"
                      placeholder="请选择承运人"
                      :reduce="option => option.label"
                  />
                </b-form-group>
              </b-col>
              <!--车牌号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="车牌号"
                    label-for="car_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="car_no"
                      size="sm"
                      v-model="condition.car_no"
                  />
                </b-form-group>
              </b-col>
              <!--费用日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="费用日期"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.date"
                      class="form-control"
                      :config="rangeConfig"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <!--复选框-->
        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(car_type)="data">
          {{ getCodeLabel('warehouse_feed_cartype', data.item.car_type) }}
        </template>

        <!--费用日期-->
        <template #cell(feed_date)="data">
          {{ toDate(data.item.feed_date) }}
        </template>

        <!--更新时间-->
        <template #cell(modify_time)="data">
          {{ toTime(data.item.modify_time) }}
        </template>

        <!--更新人-->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--备注-->
        <template #cell(remark)="data">
          <div :id="`memo-${data.item.id}`" style="width: 55px">
            <span v-if="data.item.remark">
              {{ data.item.remark.substring(0, 3) }}
              <span v-if="data.item.remark.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo-${data.item.id}`" placement="top">
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-logisticsfeed-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeState(data.item.id)">
              <feather-icon icon="Trash2Icon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import logisticsfeedUseList from './logisticsfeedUseList'
import logisticsfeedStore from './logisticsfeedStore'
import {useToast} from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import axios from '@axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
  },
  data() {
    return {
      carriager_options: [],
      customer_name_options: [],
      isShowCard: false,
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
      rangeConfig: {
        mode: 'range',
      },
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('logisticsfeed')) store.registerModule('logisticsfeed', logisticsfeedStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('logisticsfeed')) store.unregisterModule('logisticsfeed')
    })

    const changeState = function (id) {
      this.$swal({
        title: '确定删除吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('logisticsfeed/state', {ids: id}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //清空勾选
              this.clearSelected()
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const initOptions = function () {
      let that = this
      axios.post('/api/logisticsuser/list').then(function (res) {
        let list = res.data.data.list
        list.forEach(item => {
          if (item.user_type === 1) {
            that.customer_name_options.push({label: item.user_name})
          } else if (item.user_type === 2) {
            that.carriager_options.push({label: item.user_name})
          }
        })
      })
    }

    const delByIds = function () {
      if (this.checked.length < 1) {
        toast.error('请选择一条记录')
        return
      }
      let ids = []
      this.checked.forEach(item => {
        ids.push(item.id)
      })
      this.changeState(ids.toString())
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      let checkedIds = [];
      this.checked.forEach(item => {
        checkedIds.push(Number(item.id))
      })
      this.table.forEach(element => {
        if (checked) {
          if (checkedIds.indexOf(element.id) === -1) {
            this.checked.push(element);
          }
        } else {
          //取消勾选
          for (let i = 0; i < this.checked.length; i++) {
            //在已勾选数组中找出要取消勾选的
            if (this.checked[i].id == element.id) {
              this.checked.splice(i, 1)
            }
          }
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if (this.isCheckedObj['index' + item.id]) {
        //勾选
        this.checked.push(item)
      } else {
        //取消勾选
        for (let i = 0; i < this.checked.length; i++) {
          //在已勾选数组中找出要取消勾选的
          if (this.checked[i].id == item.id) {
            this.checked.splice(i, 1)
          }
        }
      }
      this.$forceUpdate()
    }
    //清空勾选
    const clearSelected = function () {
      this.checked.forEach(item => {
        this.isCheckedObj['index' + item.id] = false;
      })
      this.checked = []
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = logisticsfeedUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeState,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      initOptions,
      delByIds,
      toggleAll,
      checkboxClick,
      clearSelected,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    this.initOptions()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
